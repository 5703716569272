import Checkbox from "../Checkbox";
import "./data-filter.scss";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../button";
import OutsideClickHandler from "react-outside-click-handler";
import { Json } from "../../types";
import { Input } from "@storybook";
import { searchedTextState } from "./state";
import { useRecoilState } from "recoil";

interface IDataFilter {
  data: Json[];
  position?: "left" | "right";
  selectedValue: Json;
  onChangeFilter: (obj: Json) => void;
  onClickReset: () => void;
  isLoading?: boolean;
  buttonDisable?: boolean;
  hideFilter?: boolean;
}

export const DataFilter = ({
  data,
  selectedValue,
  position = "left",
  onChangeFilter,
  onClickReset,
  isLoading,
  buttonDisable = false,
  hideFilter=false
}: IDataFilter) => {
  const [searchedText, setSearchedText] = useRecoilState(searchedTextState);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilterOptions, setActiveFilterOptions] = useState("");
  const toggleFilter = () => {
    setActiveFilterOptions("");
    setIsFilterOpen((prev) => !prev);
  };

  const handleCloseFilter = () => {
    setActiveFilterOptions("");
    setIsFilterOpen(false);
  };

  const toggleFilterOptions = (key: string) => {
    if (activeFilterOptions === key) {
      setActiveFilterOptions("");
    } else {
      setActiveFilterOptions(key);
    }
  };

  const handleClickOnCheckbox = (key: string, value: string) => {
    if (selectedValue?.[key]?.length) {
      const valuesSet: any = new Set(selectedValue?.[key]?.split(","));
      if (valuesSet.has(value)) {
        valuesSet.delete(value);
        return onChangeFilter({ [key]: [...valuesSet].join(",") });
      }
      onChangeFilter({ [key]: [...valuesSet, value].join(",") });
    } else {
      onChangeFilter({ [key]: value });
    }
  };

  const totalSelectedNumber = useMemo(() => {
    let count = 0;
    data?.map((items: any) => {
      if (selectedValue?.[items.key]?.length) {
        count += selectedValue?.[items.key]?.split(",")?.length;
      }
    });
    return count;
  }, [selectedValue]);

  const filterData = useCallback(
    (items: any) => {
      if (items.header === "Users" && searchedText) {
        const filteredOptions = items.options.filter((filterItem: any) =>
          filterItem.label.toLowerCase().includes(searchedText.toLowerCase())
        );

        if (filteredOptions.length === 0) {
          return [{ label: "User Not Found", value: "" }];
        }

        return filteredOptions;
      } else {
        return items.options;
      }
    },
    [searchedText]
  );

  const containsExactWord = (str: string, word: string) => {
    // Construct a regular expression pattern to match the word surrounded by word boundaries
    const regex = new RegExp("\\b" + word + "\\b", "i"); // 'i' flag for case-insensitive matching

    // Test if the string matches the regular expression
    return regex.test(str);
  };

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchedText(value);
    },
    [setSearchedText]
  );

  useEffect(() => {
    if (!isFilterOpen) {
      setSearchedText("");
    }
  }, [isFilterOpen]);

  return (
   <>{!hideFilter &&  <div className="filter-container">
    <OutsideClickHandler onOutsideClick={handleCloseFilter}>
      <Button
        handleClick={toggleFilter}
        label={
          // totalSelectedNumber ? totalSelectedNumber + " Selected" :
          "Filters"
        }
        className="btn-grey btn-h-auto"
        prefixIcon="ri-filter-3-line"
        disabled={buttonDisable}
      />

      {totalSelectedNumber > 0 && (
        <div className="filter-count">{totalSelectedNumber}</div>
      )}
      {isFilterOpen && (
        <div className={`filter-container-body-wrapper ${position}`}>
          <div className="filter-container-header">
            <p>Filters</p>
            <Button
              label="Reset All"
              className="button-white btn-h-auto"
              handleClick={onClickReset}
            />
          </div>

          <div className="filter-container-body">
            {data?.map((items: any) => {
              return (
                <div className="filter-container-main" key={items.key}>
                  <div
                    onClick={() => toggleFilterOptions(items.key)}
                    className="filter-container-main-header"
                  >
                    <p>
                      {items.header}
                      {!!selectedValue?.[items.key]?.length && (
                        <span>
                          {selectedValue?.[items.key]?.split(",")?.length}{" "}
                          Selected
                        </span>
                      )}
                    </p>
                    <span
                      className={`ri ${
                        activeFilterOptions === items.key
                          ? "ri-arrow-up-s-line"
                          : "ri-arrow-down-s-line"
                      }`}
                    ></span>
                  </div>

                  {activeFilterOptions === items.key && (
                    <div className="filter-container-options">
                      {items.header === "Users" && (
                        <Input
                          inputType="text"
                          handleChange={handleSearch}
                          placeholder="Search"
                          allowClear={false}
                          handleClearField={() => {}}
                          prefixIcon="ri-search-line"
                          value={searchedText}
                          label={""}
                        />
                      )}
                      <div className="filter-options">
                        {filterData(items)?.map((option: any) => {
                          if (option.label === "User Not Found") {
                            return (
                              <div className="no-users-found">
                                {option.label}
                              </div>
                            );
                          }

                          return (
                            <Checkbox
                              disabled={isLoading}
                              key={option.value}
                              note=""
                              className={""}
                              value={containsExactWord(
                                selectedValue?.[items.key],
                                option.value
                              )}
                              onChange={() =>
                                handleClickOnCheckbox(items.key, option.value)
                              }
                              content={option.label}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </OutsideClickHandler>
  </div>}</>
  );
};
