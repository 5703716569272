import "./access-denied.scss";
import accessDeniedImage from "../../assets/images/accessdenied.svg";
import liquidityLogoImage from "../../assets/images/liquidityLogo.svg";
import { useHandleLogout } from "hooks";

export const AccessDenied = () => {
  const { handleLogout } = useHandleLogout();

  return (
    <div className="access-denied-container">
      <img
        className="header-logo"
        src={liquidityLogoImage}
        alt="liquidity Logo"
      />

      <div className="access-denied-details ">
        <img
          className="access-denied-image"
          src={accessDeniedImage}
          alt="access denied"
        />
        <p className="text-url">Access Denied</p>
        <p className="access-invalid">
          Sorry, but your access to the system has been blocked. If you believe
          this is an error or have any questions, please contact admin for
          further assistance.
        </p>
        <button className="accessdenied-logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};
