import { Input, DataFilter } from "@storybook";
import "./filter.scss";

export const Filter = ({
  inputValue,
  handleInputChange,
  data,
  filters,
  handleFilter,
  onReset,
  filterDropDownVisible= true
}: any) => {
  const change = (value: string) => {
    handleInputChange(value);
  };

  return (
    <div className="filter__container">
      <div className="filter__container-search">
        <Input
          placeholder="Search"
          label=""
          inputType="text"
          value={inputValue}
          handleChange={(e) => change(e.target.value)}
          suffixIcon="ri ri-search-line"
        />
        {
          filterDropDownVisible &&
          <DataFilter
          selectedValue={filters}
          data={data}
          position="left"
          onChangeFilter={handleFilter}
          onClickReset={onReset}
          buttonDisable={true}
          hideFilter={true}
          // isLoading={loading}
        />
        }
        
      </div>
    </div>
  );
};
